<template>
  <my-nav></my-nav>
  <router-view />
</template>

<script>
import MyNav from "./components/ui/MyNav.vue";

export default {
  components: {
    MyNav,
  },
  created() {
    document.title = "Fairlight";
  },
};
</script>

<style>
</style>
