<template>
  <div class="terminal-nav">
    <header class="terminal-logo">
      <div class="logo terminal-prompt">
        <router-link to="/" class="no-style">Fairlight</router-link>
        <span v-if="os !== '' && browser !== ''" class="weak padding-left"
          >{{ browser }}|{{ os }}</span
        >
      </div>
    </header>
    <nav class="terminal-menu">
      <ul vocab="https://schema.org/" typeof="BreadcrumbList">
        <li>
          <router-link to="/" class="menu-item"><span>home</span></router-link>
        </li>
        <li>
          <router-link to="/projects" class="menu-item"
            ><span property="name">projects</span></router-link
          >
        </li>
        <li>
          <router-link to="/contact" class="menu-item"
            ><span property="name">contact</span></router-link
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      os: "",
      browser: "",
      currentRouteName: null,
    };
  },
  created() {
    this.os = this.getOS();
    this.browser = this.getBrowser();
  },
  watch: {
    $route: "currentRoute",
  },
  methods: {
    currentRoute() {
      this.$nextTick(() => {
        this.currentRouteName = this.$route.name;
      });
    },
    getOS() {
      var userAgent = window.navigator.userAgent,
        platform =
          window.navigator?.userAgentData?.platform ??
          window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K", "macOS"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
      } else if (/Android/.test(userAgent)) {
        os = "Android";
      } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
      } else {
        os = "Unknown";
      }
      return os;
    },
    getBrowser() {
      const { userAgent } = navigator;
      let match =
        userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
      let temp;

      if (/trident/i.test(match[1])) {
        temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];

        return `IE ${temp[1] || ""}`;
      }

      if (match[1] === "Chrome") {
        temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);

        if (temp !== null) {
          return temp.slice(1).join(" ").replace("OPR", "Opera");
        }

        temp = userAgent.match(/\b(Edg)\/(\d+)/);

        if (temp !== null) {
          return temp.slice(1).join(" ").replace("Edg", "Edge (Chromium)");
        }
      }
      match = match[2]
        ? [match[1], match[2]]
        : [navigator.appName, navigator.appVersion, "-?"];
      temp = userAgent.match(/version\/(\d+)/i);

      if (temp !== null) {
        match.splice(1, 1, temp[1]);
      }

      return match.join(" ");
    },
  },
};
</script>

<style scoped>
.weak {
  color: grey;
}
.padding-left {
  margin-left: 1em;
}
</style>ƒ