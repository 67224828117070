<template>
  <section class="main">
    <base-card>
      <blockquote>
        me: freelance full-stack dev
        <br />
        been coding for well over a decade
      </blockquote>
    </base-card>    
    <base-card>
      <h1>here is what I usually build for you</h1>
      <blockquote>
        web apps
        <br />
        mobile apps
        <br />
        cloud deployment
        <br />
        custom video conferencing (like zoom)
        <br />
        chat
        <br />
        databasification for machine learning / AI
        <br />
        business automations
        <br />
        automated email and sms messaging
        <br />
        shops / payments
        <br />
        client management
        <br />
        ERP solutions
      </blockquote>
    </base-card>
    <base-card>
      <h1>things you will love</h1>
      <p>
        I build in-house. In other words, I do not outsource. 
        If you explain what you want, it will be I who actually builds it the way you need it 
        instead of me simply making money as a software agency.
      </p>
      <blockquote>
        I build from scratch according to client wishes.
        <br />
        I communicate well in English, German, Korean, and Japanese.
        <br />
      </blockquote>
    </base-card>
    <base-card>
      <h1>some of the tech I use</h1>
      <blockquote>
        JS, Vue, Angular, Python, Django, PHP
        <br />
        MySQL, NOSQL, Firebase
        <br />
        Micro Services
        <br />
        AWS    
      </blockquote>
    </base-card>
    <base-card>
      <blockquote>
        fairlight.link is esdikay's playground. 
      </blockquote>
    </base-card>
  </section>
</template>

<script>
import BaseCard from "../components/ui/BaseCard.vue";
// import countryJson from "../assets/countries.json";

if (typeof(Number.prototype.toRad) === "undefined") {
  Number.prototype.toRad = function() {
    return this * Math.PI / 180;
  }
}

export default {
  components: {
    BaseCard,
  },
  data() {
    return {
      // countries: countryJson,
      foundCountry: "",
      locationDone: false,
      coords: {
        latitude: 0,
        longitude: 0,
      },
    };
  },
  created() {
    // window.addEventListener("mousemove", this.mouseMoveHandler);
  },
  mounted() {
    document.title = "Fairlight - Home";
  },
  beforeUnmount() {
    // window.removeEventListener("mousemove", this.mouseMoveHandler);
  },
  watch: {
    // "coords.latitude": function (newval) {
    //   if (newval !== 0 && this.coords.longitude !== 0) {
    //     let closest = this.countries[0];
    //     let closest_distance = this.distance(closest, this.coords);
    //     for (let i = 1; i < this.countries.length; i++) {
    //       if (
    //         this.distance(this.countries[i], this.coords) < closest_distance
    //       ) {
    //         closest_distance = this.distance(this.countries[i], this.coords);
    //         closest = this.countries[i];
    //       }
    //     }
    //     this.foundCountry = closest.name;
    //   }
    // },
  },
  methods: {
    // mouseMoveHandler() {
    //   if (this.locationDone) return false;
    //   this.locationDone = true;
    //   this.getLocation();
    //   return true;
    // },

    // distance(position1, position2) {

    //   const lat1 = position1.latitude;
    //   const lat2 = position2.latitude;
    //   const lon1 = position1.longitude;
    //   const lon2 = position2.longitude;
    //   const R = 6371000; // metres
    //   const φ1 = lat1.toRad();
    //   const φ2 = lat2.toRad();
    //   const Δφ = (lat2 - lat1).toRad();
    //   const Δλ = (lon2 - lon1).toRad();

    //   const a =
    //     Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    //     Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    //   const d = R * c;
    //   return d;
    // },
    // getLocation() {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       this.savePosition,
    //       this.handleError
    //     );
    //   } else {
    //     console.error("Geolocation is not supported by this browser.");
    //   }
    // },
    // watchLocation() { // watch visitor's location 
    //   if (navigator.geolocation) {
    //     navigator.geolocation.watchPosition(
    //       this.savePosition,
    //       this.handleError
    //     );
    //   } else {
    //     console.error("Geolocation is not supported by this browser.");
    //   }
    // },
    // savePosition(position) {
    //   this.coords.longitude = position.coords.longitude;
    //   this.coords.latitude = position.coords.latitude;
    // },
    // handleError(error) {
    //   let errorStr;
    //   switch (error.code) {
    //     case error.PERMISSION_DENIED:
    //       errorStr = "User denied the request for Geolocation.";
    //       break;
    //     case error.POSITION_UNAVAILABLE:
    //       errorStr = "Location information is unavailable.";
    //       break;
    //     case error.TIMEOUT:
    //       errorStr = "The request to get user location timed out.";
    //       break;
    //     case error.UNKNOWN_ERROR:
    //       errorStr = "An unknown error occurred.";
    //       break;
    //     default:
    //       errorStr = "An unknown error occurred.";
    //   }
    //   this.userPosition = errorStr;
    //   console.error(`Error occurred: ${errorStr}`);
    // },
  },
};
</script>

<style>
</style>